<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Ödeme Bilgisi</b-card-title>
      </b-card-header>
      <b-table-simple>
        <b-thead>
          <b-tr>
            <b-th>Ödeme Tipi</b-th>
            <b-th>Ödeme Tarihi</b-th>
            <b-th>Açıklama</b-th>
            <b-th class="text-right">
              Tutar
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(payment,key) in itemData.payments"
            :key="key"
          >
            <b-td>{{ payment }}</b-td>
            <b-td>{{ moment(itemData.payments.pdate[key]).format('DD.MM.YYYY') }}</b-td>
            <b-td>{{ itemData.payments.pcontent[key] }}</b-td>
            <b-td class="text-right">
              {{ itemData.payments.pprice[key] | toCurrency }} ₺
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-th colspan="3">
              Toplam
            </b-th>
            <b-th class="text-right">
              {{ itemData.totalPayment | toCurrency }} ₺
            </b-th>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
      <b-card-body>
        <b-alert
          class="m-0 text-center"
          :variant="itemData.totalPayment >= itemData.sale_price? 'success' : 'danger'"
          show
        >
          <div class="alert-body">
            {{ itemData.totalPayment >= itemData.sale_price? 'Açık bakiye bulunmamaktadır.' : 'Açık bakiye bulunmaktadır. Lütfen ödeme bilgilerinizi kontrol edin.' }}
          </div>
        </b-alert>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BAlert, BCardHeader, BCardTitle, BCardBody, BTableSimple, BTbody, BTd, BTh, BThead, BTr, BTfoot,
} from 'bootstrap-vue'

export default {
  name: 'Payments',
  components: {
    BCard,
    BAlert,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BTableSimple,
    BTbody,
    BTd,
    BTh,
    BThead,
    BTr,
    BTfoot,
  },
  computed: {
    itemData() {
      return this.$store.getters['i2sales/getI2Sale']
    },
  },
}
</script>
